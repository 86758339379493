export type BinaryValue = 'Y' | 'N'

export type ApiSuccessResponse<T> = {
  code: number
  status: string
  response: T
}

export type ApiFailureResponse = {
  statusCode: number
  message: string
  HasError: boolean
  IsControlledException: boolean
}

export enum Requests {
  getCustomerSupport = 'getCustomerSupport',
  getOrders = 'getOrders',
  getPendingOrders = 'getPendingOrders',
  getOrderDetails = 'getOrderDetails',
  getOrderLineItems = 'getOrderLineItems',
  getDashboard = 'getDashboard',
  getIndustries = 'getIndustries',
  getInterests = 'getInterests',
  getUserIndustries = 'getUserIndustries',
  getUserInterests = 'getUserInterests',
  uploadCustomerPhoto = 'uploadCustomerPhoto',
  getCustomerRoles = 'getCustomerRoles',
  getCustomers = 'getCustomers',
  uploadCustomers = 'uploadCustomers',
  createCustomers = 'createCustomers',
  updateCustomers = 'updateCustomers',
  updateUserIndustries = 'updateUserIndustries',
  updateUserInterests = 'updateUserInterests',
  inviteTeamMembers = 'inviteTeamMembers',
  getTaxonomy = 'getTaxonomy',
  getRiversandItem = 'getRiversandItem',
  getMoldCode = 'getMoldCode',
  getCustomerBU = 'getCustomerBU',
  getVendor = 'getVendor',
  getSupplierPlanner = 'getSupplierPlanner',
  getDemandPlanner = 'getDemandPlanner',
  getHSCode = 'getHSCode',
  getHTSCode = 'getHTSCode',
  getAttributes = 'getAttributes',
  getNewItems = 'getNewItems',
  getNewItem = 'getNewItem',
  saveNewItem = 'saveNewItem',
  updateNewItem = 'updateNewItem',
  getDashboardItem = 'getDashboardItem',
  getTaxanomyAttribute = 'getTaxanomyAttribute',
  getMainTaxanomy = 'getMainTaxanomy',
  getDashboardPageItem = 'getDashboardPageItem',
  getFrameworkPageItem = 'getFrameworkPageItem',
  uploadAsset = 'uploadAsset',
  getCustomerPhoto = 'getCustomerPhoto',
  downloadInvoice = 'downloadInvoice',
  getProducts = 'getProducts',
  getProductDetails = 'getProductDetails',
  getPixels = 'getPixels',
  getWishlists = 'getWishlists',
  getProductInventory = 'getProductInventory',
  uploadProductImage = 'uploadProductImage',
  getInvoices = 'getInvoices',
  getInvoiceItems = 'getInvoiceItems',
  requestInvoice = 'requestInvoice',
  getFinanceFiles = 'getFinanceFiles',
  getFinanceFileEnities = 'getFinanceFileEnities',
  uploadFinanceFile = 'uploadFinaceFile',
  resetUserPassword = 'resetUserPassword',
  getCustomerLookups = 'getCustomerLookups',

  // checkout
  submitTaxExempt = 'submitTaxExempt',

  // b2b cart/checkout
  getCart = 'getCart',
  addToCart = 'addToCart',
  removeFromCart = 'removeFromCart',
  updateCartItem = 'updateCartItem',
  updateCart = 'updateCart',
  getCheckout = 'getCheckout',
  getCustomerAddresses = 'getCustomerAddresses',
  addCustomerAddress = 'addCustomerAddress',
  addressValidationSearchCustomerAddress = 'addressValidationSearchCustomerAddress',
  addressValidationGetFormattedAddress = 'addressValidationGetFormattedAddress',
  addressValidationValidate = 'addressValidationValidate',
  createConsignment = 'createConsignment',
  updateConsignment = 'updateConsignment',
  deleteConsignment = 'deleteConsignment',
  addBillingAddress = 'addBillingAddress',
  addShippingOption = 'addShippingOption',
  createOrder = 'createOrder',

  // dashboard
  getCustomerDetails = 'getCustomerDetails',
  getRecentOrders = 'getRecentOrders',
  getRecommendedProducts = 'getRecommendedProducts',
  getNewFeatures = 'getNewFeatures',
  getArticles = 'getArticles',
  getKpi = 'getKpi',

  // config
  getFeatureFlags = 'getFeatureFlags',

  // notifications
  getNotifications = 'getNotifications',
  getNotificationsOrderFilters = 'getNotificationsOrderFilters',
  getNotificationTemplates = 'getNotificationTemplates',
  updateNotificationStatus = 'updateNotificationStatus',
  createNotification = 'createNotification',
  updateNotification = 'updateNotification',
  deleteNotification = 'deleteNotification',
}

export type OmniChannelFeature =
  | 'dark-mode'
  | 'profile-uploader'
  | 'wishlist'
  | 'checkout'
  | 'cart'
  | 'header-searchbar'
  | 'compare-products'
  | 'checkout-add-address'
  | 'site-maintenance'
