// material-ui
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'

import ProfileSection from './ProfileSection'
import SearchSection from './SearchSection'
import CartSection from './CartSection'
import NavSection from './NavSection'
import MobileMenu from './MobileMenu'

import { getLowerEnv, isProduction, isQorpak } from '../../../utils/common'
import { logoutRequest } from '../../../authConfig'
import { resetState } from '../../../stores/rootReducer'
import { clearCart } from '../../../stores/b2b/CartStore/cartSlice'
import { storeUserCart } from '../../../stores/b2b/CartStore/actions'
import { setRole, setLogoutInProgress } from '../../../stores/AuthStore/authSlice'
import { getPlatform, trackClick, trackNavigationMenu } from '../../../utils/segment'
import { deleteCookie } from '../../../utils/cookie'
import LogoSection from '../LogoSection'
// import NotificationSection from './NotificationSection'
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks'
import { selectRole, selectUser } from '../../../stores/AuthStore/selectors'
import { UserRole } from '../../../types/user'
import { selectIsCrossBrandUser } from '../../../stores/UserStore/selectors'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

type Props = {
  shouldDisplayProducts: boolean
}

declare global {
  interface Window {
    gtranslateSettings: any // Declare gtranslateSettings on window
  }
}

const Header = ({ shouldDisplayProducts = false }: Props) => {
  const user = useAppSelector(selectUser)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [catalogHref, setCatalogHref] = useState<string>('')
  const theme = useTheme()
  const isCheckout = window.location.href?.includes('checkout')
  const isB2BCheckoutEnabled = user?.userRoles?.includes('Customer B2B Checkout')
  const isMobile = useMediaQuery(theme.breakpoints.down(800)) // 800 is the min width where all elements are still stackable horizontally
  const isDesktop = useMediaQuery(theme.breakpoints.up(801))
  const userRole = useAppSelector(selectRole)
  const { instance } = useMsal()
  const dispatch = useAppDispatch()
  const iconColor = isQorpak() ? theme.palette.common.qorpakSecondary : theme.palette.common.primaryBlue
  const isCrossBrandUser = useAppSelector(selectIsCrossBrandUser)

  const updateTextNode = (langCode: string) => (node: ChildNode) => {
    if (node.nodeType === 3) {
      node.nodeValue = ` ${langCode.toUpperCase()}`
    }
  }

  const updateLanguageOption = (option: Element) => {
    const langCode = option.getAttribute('data-gt-lang')
    if (langCode) {
      option.childNodes.forEach(updateTextNode(langCode))
      // Segment tracking
      option.addEventListener('click', () => {
        trackNavigationMenu('Language translator')
      })
    }
  }

  const enhanceLanguageOptions = () => {
    document.querySelectorAll('.gt_options a').forEach(updateLanguageOption)
  }

  const setupGTranslate = () => {
    window.gtranslateSettings = {
      default_language: 'en',
      detect_browser_language: true,
      languages: ['en', 'fr', 'es'],
      wrapper_selector: '.gTranslateWrapper',
      float_switcher_open_direction: 'bottom',
      alt_flags: { en: 'usa', fr: 'canada' },
    }

    const script = document.createElement('script')
    script.src = 'https://cdn.gtranslate.net/widgets/latest/float.js'
    script.id = 'GTranslateScript'
    script.async = true
    document.head.appendChild(script)
    script.onload = enhanceLanguageOptions
  }

  useEffect(() => {
    if (!isQorpak() && ((isMobile && !isDesktop) || (isDesktop && !isMobile))) {
      const existingScript = document.getElementById('GTranslateScript')
      if (!existingScript) setupGTranslate()

      // return () => {
      //   const existingScript = document.getElementById('GTranslateScript')
      //   if (existingScript) existingScript.remove()
      //   document.getElementById('gt_float_wrapper')?.remove()
      //   document.getElementById('google_translate_element2')?.remove()
      // }
    }
  }, [isMobile, isDesktop])

  useEffect(() => {
    if (isQorpak()) {
      setCatalogHref(`https://${isProduction() ? '' : getLowerEnv() + '.'}shop.qorpak.com/products/categories/index`)
    } else {
      setCatalogHref('/products/list')
    }
  }, [])

  const handleLogout = async () => {
    // Segment tracking
    trackClick('Log Out', {
      label: 'Log Out',
      platform: getPlatform(userRole === UserRole.EMPLOYEE),
      category: 'Dashboard',
    })
    dispatch(storeUserCart())
    dispatch(setRole(null))
    dispatch(setLogoutInProgress(true))
    window.localStorage.removeItem('token')
    dispatch(clearCart())
    if (userRole === UserRole.EMPLOYEE) {
      window.location.href = 'https://berlin.okta.com'
    } else {
      deleteCookie(`x-ms-cpim-sso:${process.env.REACT_APP_B2C_TENANT_DOMAIN ?? 'bpkgomnitest.onmicrosoft.com'}_0`)
      dispatch(resetState())
      instance.logoutRedirect(logoutRequest)
    }
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!isQorpak() && (
          <Box
            sx={{
              borderBottom: '1px solid #CECECE',
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '1500px',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: '16px',
                height: '58px',
              }}
            >
              <Box
                sx={{
                  height: '38px',
                  border: '1px solid #CECECE',
                  borderRadius: '4px',
                }}
                className="gTranslateWrapper"
              ></Box>

              {!isMobile && (
                <Box
                  sx={{
                    display: 'flex',
                    zIndex: 999,
                  }}
                >
                  <ProfileSection handleLogout={handleLogout} />
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            width: '100%',
            maxWidth: '1500px',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: isQorpak() ? '84px' : '104px',
            px: '16px',
          }}
        >
          {/* logo */}
          {isQorpak() ? (
            <Box sx={{ flexShrink: 0 }}>
              <LogoSection />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '50px',
              }}
            >
              {/* logo */}
              <Box sx={{ flexShrink: 0 }}>
                <LogoSection />
              </Box>

              {!isCrossBrandUser && !isMobile && (
                <NavSection shouldDisplayCatalog={(shouldDisplayProducts && !isQorpak()) || isQorpak()} catalogHref={catalogHref} />
              )}
            </Box>
          )}

          {/* interactive components */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: isQorpak() ? '16px' : '8px',
            }}
          >
            {!isCrossBrandUser && (
              <>
                {/* search */}
                {shouldDisplayProducts && (
                  <Box sx={{ width: { xs: '100%', md: isQorpak() ? '200px' : '300px' }, flexGrow: { xs: 1, md: 0 } }}>
                    <SearchSection />
                  </Box>
                )}

                {/* links */}
                {!isMobile && isQorpak() && (
                  <NavSection shouldDisplayCatalog={(shouldDisplayProducts && !isQorpak()) || isQorpak()} catalogHref={catalogHref} />
                )}

                {/* checkout */}
                {isB2BCheckoutEnabled && !isCheckout && <CartSection />}
              </>
            )}

            {/* profile */}
            {!isMobile && isQorpak() && (
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <ProfileSection handleLogout={handleLogout} />
              </Box>
            )}

            {isMobile && (
              <IconButton
                onClick={() => setMobileMenuOpen(true)}
                sx={{
                  color: theme.palette.common.black,
                  background: 'none !important',
                  p: '4px',
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>

      {isMobile && (
        <MobileMenu
          isOpen={mobileMenuOpen}
          setIsOpen={setMobileMenuOpen}
          handleLogout={handleLogout}
          shouldDisplayCatalog={(shouldDisplayProducts && !isQorpak()) || isQorpak()}
          iconColor={iconColor}
          catalogHref={catalogHref}
        >
          <Box
            sx={{
              marginTop: '-60px',
              paddingBottom: '1rem',
              padding: '15px 24px',
              backgroundColor: '#F7F9FB',
              borderBottom: '1px solid #EEEEEE',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: '40px 0px 24px',
              }}
            >
              <span style={{ fontSize: '24px', lineHeight: '120%', fontWeight: 600, color: theme.palette.common.black }}>
                {user?.firstName} {user?.lastName}
              </span>
              <span style={{ fontSize: '16px', lineHeight: '120%', fontWeight: 400, color: '#0179C2', textDecoration: 'underline' }}>
                {user?.company}
              </span>
            </Box>
          </Box>
        </MobileMenu>
      )}
    </>
  )
}

export default Header
