import { ReactNode } from 'react'
// material-ui
import { Box, Button, Typography, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { PersonAddAlt } from '@mui/icons-material'

import { useAppSelector, useAppDispatch } from '../../../../hooks/reduxHooks'
import { selectRole, selectUser } from '../../../../stores/AuthStore/selectors'
import { UserRole } from '../../../../types/user'
import { BoxIcon, CatalogIcon, ClipboardDollarIcon, InvoiceIcon, NotificationIcon } from '../../../../components/icons'
import { setProductFilters } from '../../../../stores/ProductsStore/productsSlice'
import { isQorpak } from '../../../../utils/common'
import { trackNavigationMenu } from '../../../../utils/segment'
import { reset } from '../../../../stores/InvoicesStore/invoicesSlice'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const NavButton = ({ text, icon, onClick }: { text: string; icon?: ReactNode; onClick: () => void }) => {
  const theme = useTheme()

  return (
    <Button
      onClick={onClick}
      startIcon={icon}
      sx={{
        p: isQorpak() ? '4px' : '8px',
        color: theme.palette.common.black,
        '&:hover, &:focus': {
          background: 'none',
          color: `${theme.palette.common.black} !important`,
        },
        '.MuiButton-startIcon': {
          ml: 0,
          mr: '4px',
        },
      }}
    >
      <Typography
        sx={{
          fontSize: isQorpak() ? '14px' : '16px',
          fontWeight: isQorpak() ? 500 : 600,
          textTransform: 'none',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Typography>
    </Button>
  )
}

type Props = {
  shouldDisplayCatalog: boolean
  catalogHref?: string
}
const NavSection = ({ shouldDisplayCatalog = false, catalogHref }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const userRole = useAppSelector(selectRole)
  const isFinanceEnabled = user?.userRoles?.includes('Employee Finance')
  const isUserManagementEnabled = user?.userRoles?.includes('Employee User-Management')
  const isEmployee = userRole === UserRole.EMPLOYEE

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: isQorpak() ? '16px' : '8px',
        '&:hover': !isQorpak()
          ? {
              button: {
                color: '#808080',
              },
            }
          : {},
        py: 1,
      }}
    >
      {!isQorpak() && (
        <NavButton
          onClick={() => {
            navigate('/')
          }}
          text="Home"
        />
      )}
      {isEmployee ? (
        <>
          <NavButton
            onClick={() => {
              navigate('/')
              trackNavigationMenu('Orders')
            }}
            icon={<BoxIcon />}
            text="Orders"
          />
          {isUserManagementEnabled && (
            <NavButton
              onClick={() => {
                navigate('/employee/user-management')
                trackNavigationMenu('User Management')
              }}
              icon={<PersonAddAlt sx={{ color: theme.palette.common.black }} />}
              text="User Management"
            />
          )}
          {isFinanceEnabled && (
            <NavButton
              onClick={() => {
                navigate('/employee/finance')
                trackNavigationMenu('finance')
              }}
              icon={<ClipboardDollarIcon />}
              text="Finance"
            />
          )}
        </>
      ) : (
        <NavButton
          onClick={() => {
            navigate('/orders')
            trackNavigationMenu('Orders')
          }}
          icon={isQorpak() && <BoxIcon />}
          text="Orders"
        />
      )}
      {!isEmployee && !isQorpak() && (
        <NavButton
          onClick={() => {
            dispatch(reset())
            navigate('/invoices')
            trackNavigationMenu('Invoices')
          }}
          icon={isQorpak() && <InvoiceIcon />}
          text="Invoices"
        />
      )}
      {shouldDisplayCatalog && (
        <NavButton
          onClick={() => {
            // clear filters first before navigating to prevent API being triggered twice
            dispatch(setProductFilters({ filters: {}, searchString: '', page: 1, perPage: 12, sortBy: 'NONE' }))
            if (catalogHref && location.pathname !== catalogHref) {
              isQorpak() ? window.open(catalogHref, '_blank') : navigate(catalogHref)
            }
            trackNavigationMenu('My Products')
          }}
          icon={isQorpak() && <CatalogIcon />}
          text="My Products"
        />
      )}
      {!isEmployee && !isQorpak() && (
        <NavButton
          onClick={() => {
            navigate('/notifications')

            // Segment tracking
            trackNavigationMenu('My Notifications')
          }}
          icon={isQorpak() && <NotificationIcon />}
          text="My Notifications"
        />
      )}
      {/* {!isQorpak() && <Box className="gTranslateWrapper"></Box>} */}
    </Box>
  )
}

export default NavSection
